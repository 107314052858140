import loadPostRenderScripts from "@helpers/loadPostRenderScripts";
import Router, { useRouter } from "next/router";
import queryString from "query-string";
import React, { useEffect } from "react";
import { StickyContainer } from "react-sticky";
import styled, { ThemeProvider } from "styled-components";
import CartProvider from "./context/Cart.context";
import PortalProvider from "./context/Portal.context";
import UserProvider from "./context/User.context";
import Meta from "./Meta";
import GlobalStyle from "./styles/GlobalStyle";
import { theme } from "./styles/Theme";

const StyledPage = styled.div`
  background: white;
`;

const saveQueryString = (query) => {
  const keys = Object.keys(query);
  const localData = JSON.parse(localStorage.getItem("query")) || {};
  keys.forEach((key) => {
    if (["gclid", "msclkid"].includes(key.toLowerCase())) {
      localData[key] = query[key];
    } else {
      if (!localData[key]) {
        localData[key] = "";
      }
      const propertyArr =
          localData[key].length > 0 ? localData[key].split(", ") : [];
      localData[key] = [...propertyArr, query[key]].join(", ");
    }
  });
  localStorage.setItem("query", JSON.stringify(localData));
};

Router.events.on("routeChangeComplete", () => {
  const query = queryString.parse(window.location.search);
  saveQueryString(query);
});

const Page = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    setTimeout(function () {
      document.body.classList.remove("vwo-hide");
    }, 750);
  }, []);

  useEffect(() => {
    if(router.query){
      // Save query on dynamic routes
      saveQueryString(router.query);
      loadPostRenderScripts();
    }
  }, [router.query]);

  return (
      <PortalProvider>
        <UserProvider>
          <CartProvider>
            <ThemeProvider theme={theme}>
              <StyledPage>
                <Meta />
                <GlobalStyle />
                <StickyContainer>{children}</StickyContainer>
              </StyledPage>
            </ThemeProvider>
          </CartProvider>
        </UserProvider>
      </PortalProvider>
  );
};

export default Page;
