export const theme = {
  primary: '#89D3C6',
  primaryDark: '#3F5261',
  primaryDark2: '#2c8373',

  secondary: '#f15f22',

  secondaryOrange: '#FD9331',
  secondaryBlue: '#2698EB',

  gray100: '#f8f9fa',
  gray150: '#f4f3f8',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#212529',

  textColor: '#343a40',

  shadow: '#00000033',
  boxShadow:
    '0 15px 20px 0 rgba(42, 51, 83, 0.12), 0 5px 15px rgba(0, 0, 0, 0.06)',

  media: {
    xs: '@media(min-width: 375px)',
    sm: '@media (min-width: 576px)',
    md: '@media (min-width: 768px)',
    lg: '@media (min-width: 992px)',
    xl: '@media (min-width: 1200px)',
    xxl: '@media (min-width: 1400px)',
  },
};
