import Axios from 'axios';
import { SiteAPI } from '../config';

class UserService {
  getCurrentUser = async () => {
    let token = localStorage.getItem('token');
    if (!token && token !== undefined) {
      token = await this.checkToken();
      if (token) localStorage.setItem('token', token);
    }
    let user = false;
    if (token) {
      const res = await fetch(SiteAPI.currentUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      user = await res.json();
    }
    return user;
  };

  checkToken = async () => {
    const url = SiteAPI.checkUser;
    const options = {
      params: SiteAPI.credentials.website,
    };

    const response = await Axios.get(url, options);

    if (response.data.authorized) {
      return response.data.access_token;
    }
    return false;
  };
}

export default UserService;
