import React, { Component } from 'react';
import Router from 'next/router';
import Cookies from 'js-cookie';
import axios from 'axios';
import { SiteAPI } from '../../config';
import UserService from '../../services/UserService';
import { SiteAPIURL } from '../../api';

export const PortalContext = React.createContext();

const saveAccessToken = token => {
  localStorage.setItem('token', token);
};

const saveUserId = userId => {
  Cookies.set('userId', userId);
};

const getClientId = () =>
  window && window.ga && window.ga.getAll && window.ga.getAll()[0].get('clientId');

class PortalProvider extends Component {
  constructor() {
    super();

    this.UserService = new UserService();
  }

  state = {
    user: false,
  };

  componentDidMount = async () => {
    if (!this.state.user) {
      const user = await this.UserService.getCurrentUser();
      this.setState({ user });
    }
  };

  signOut = () => {
    this.setState({ user: false });
    localStorage.removeItem('token');
    Cookies.remove('userId');
    Cookies.remove('partner_user_ID');

    const currentLocation = window.location.href;

    Router.push(
      `${SiteAPIURL}logout?client_id=${
        SiteAPI.credentials.portal.client_id
      }&client_secret=${
        SiteAPI.credentials.portal.client_secret
      }&response_type=token&redirect_uri=${encodeURIComponent(
        currentLocation
      )}&immediate_redirect=true`
    );
  };

  registerUser = async (userData, redirect = false, url = SiteAPI.getStarted) => {
    const credentials = SiteAPI.credentials.website;
    credentials.grant_type = 'password';
    const queryData = JSON.parse(localStorage.getItem('query')) || {};

    const registerData = { ...userData, ...credentials, ...queryData };

    const clientId = getClientId();
    if (clientId) registerData.clientId = clientId;

    if (registerData.phone) {
      registerData.phone = registerData.phone.replace(/\D/g, '');
    }

    try {
      const { data } = await axios({
        method: 'post',
        url,
        data: registerData,
      });

      if (data.access_token && data.access_token !== undefined) {
        saveAccessToken(data.access_token);
        const user = await this.UserService.getCurrentUser();
        this.setState({ user });
        saveUserId(user.id);
        if (redirect) Router.push(redirect);
        return user;
      }
      if (data.id) {
        return data;
      }
      return false;
    } catch (error) {
      throw error.response.data;
    }
  };

  render() {
    return (
      <PortalContext.Provider
        value={{
          state: this.state,
          registerUser: this.registerUser,
          signOut: this.signOut,
        }}
      >
        {this.props.children}
      </PortalContext.Provider>
    );
  }
}

export const PortalConsumer = PortalContext.Consumer;
export default PortalProvider;
