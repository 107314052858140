import Head from 'next/head';
import { useRouter } from 'next/router';

const Meta = () => {
  const router = useRouter();

  let href = 'https://1800accountant.com';

  if (router && router.asPath) {
    const noParamsPath = router.asPath.split(/[?#]/);
    const hasResult = noParamsPath.length > 0;
    href = hasResult ? `${href}${noParamsPath[0]}` : href;
  }

  return (
    <Head>
      <meta name='ir-site-verification-token' value='-966311912' />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />
      <meta
        property="og:image"
        content="https://1800accountant.com/static/images/default-og.png"
        key="open-graph-image"
      />
      <meta
        property="fb:app_id"
        content="226537857441736"
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@1800Accountant" />
      <meta
        name="twitter:title"
        content="1-800Accountant is America's leading tax and accounting firm, serving the needs of #entrepreneurs, #business owners, & consumers since 1999."
      />
      <meta
        name="twitter:description"
        content="Schedule a free consultation today!"
      />
      <meta name="twitter:image" content="https://1800accountant.com/static/images/default-og.png" />
      <link href={href} rel="canonical" />
      <link rel="shortcut icon" href="/static/images/favicon.png" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
    </Head>
  );
};

export default Meta;
