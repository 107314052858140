import { createGlobalStyle } from 'styled-components';
import { formSpin } from './FormStyles';

const GlobalStyle = createGlobalStyle`
  ::selection {
    background: ${props => props.theme.primary};
    color: white;
  }

  html {
    width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    color: ${props => props.theme.gray700};
    letter-spacing: .25px;
    font-display: swap;
    width: 100%;
    overflow-x: hidden;
    padding-top: 52px;
    min-height: 100vh;

    ${props => props.theme.media.md} {
      padding-top: 56.5px;
    }

    &::before {
      content: '';
      transition: background-color .2s;
    }
    &.mobile-nav-open {
      overflow: hidden;
      &::before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,.5);
        display: block;
        z-index: 10;
      }
    }

    &.hide-overflow {
      max-height: 100vh;
      overflow: hidden;
    }

    &.vwo-hide {
      opacity: 0;
    }

    p {
      font-family: Inter;
    }
  }

  .container {
    &.wide {
      ${props => props.theme.media.xxl} {
        max-width: 1360px;
      }
    }
  }

  section {
    padding: 40px 0;
    ${props => props.theme.media.md} {
    padding: 60px 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: #3F5261;
  }

  .row {
    &.narrow-gap {
      margin-right: -0.25rem;
      margin-left: -0.25rem;
      [class^="col-"] {
        padding-right: 0.25rem;
        padding-left: 0.25rem;
      }
    }
  }

  .center-content {
    text-align: center;
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.primary};
    &:hover {
      text-decoration: underline;
    }
  }
  p, ul {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
  }
  h1, .h1 {
    font-weight: 100;
    margin: 0 0 1em;
    font-size: 2rem;
    text-align: center;
    line-height: 1.375;
    ${props => props.theme.media.sm} {
      font-size: 2.5rem;
    }
    ${props => props.theme.media.md} {
      font-size: 3rem;
    }
    ${props => props.theme.media.lg} {
      font-size: 3.5rem;
    }
  }
  h2, .h2 {
    font-size: 1.875rem;
    font-weight: 100;
    margin: 0 0 1em;
    text-align: center;
    line-height: 1.3em;
    ${props => props.theme.media.sm} {
      font-size: 2rem;
    }
    ${props => props.theme.media.md} {
      font-size: 2.5rem;
    }
    ${props => props.theme.media.lg} {
      font-size: 3rem;
    }
  }
  h3, .h3 {
    font-size: 1.375rem;
    font-weight: 300;
    margin: 0 0 1em;
    ${props => props.theme.media.md} {
      font-size: 1.5rem;
    }
    ${props => props.theme.media.lg} {
      font-size: 1.625rem;;
    }
  }
  h2 + .sub-header {
    margin-top: -1em;
  }
  a {
    transition: all .2s;
  }
  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  svg {
    max-width: 100%;
  }
  .sub-header {
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    margin: 0 0 2em;
    ${props => props.theme.media.md} {
      font-size: 1.375rem;
    }
  }
  .gray-bg {
    background: ${props => props.theme.gray100};
  }
  .inverted-bg {
    background: ${props => props.theme.gray800};
    color: white;
  }

  .btn {
    appearance: none;
    display: inline-flex;
    font-weight: 400;
    font-size: 1.125rem;
    text-align: center;
    text-decoration: none;
    color: ${props => props.theme.gray700};
    white-space: nowrap;
    background-color: transparent;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    letter-spacing: 0.03125em;
    line-height: 1;
    box-shadow: 0.075rem 0.075rem 0.25rem rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    min-width: 0;
    padding: 0.625em 1.25em;
    border-radius: 2em;

    .btn-icon {
      height: 1.75em;
      width: 1.75em;
      margin: -0.75em 0.5em -0.75em -1em;
      transition: margin 0.2s;
      svg {
        height: 100%;
        display: block;
      }
    }

    &:hover {
      color: ${props => props.theme.gray700};
      text-decoration: none;
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
    }

    &:hover,
    &:focus {
      .btn-icon.animate-right {
        margin-left: -0.75em;
        margin-right: 0.25em;
      }
    }

    &:disabled {
      opacity: 0.8;
      box-shadow: none;
    }

    &:not(:disabled) {
      &:active {
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
      }
    }
  }

  .btn-lg {
    min-width: 0;
    padding: 0.875em 1.5em;
    font-size: 1.3rem;
  }

  .btn-sm {
    min-width: 0;
    font-size: .8125rem;
  }

  .btn-block {
    width: 100%;
  }

  .btn-primary {
    background: ${props => props.theme.secondary};
    border-color: ${props => props.theme.secondary};
    color: white;
    transition: .5s ease all;

    &:hover {
      filter: brightness(1.1);
    }

    &:not(:disabled):not(.disabled):active {
      background: ${props => props.theme.secondary};
      border-color: ${props => props.theme.secondary};
      box-shadow: 0 0 0 0.2rem rgb(45 163 141 / 0.5);
    }

    &:focus {
      background: ${props => props.theme.secondary};
      filter: brightness(1.1);
      box-shadow: 0 0 0 0.2rem rgb(45 163 141 / 0.5);
    }

    &:focus, &:hover, &:active {
      color: white;
    }
  }

  .btn-secondary {
    background: ${props => props.theme.secondary};
    border-color: ${props => props.theme.secondary};
    color: white;
    cursor: pointer;
    transition: .5s ease all;
    font-family: 'Inter';

    &:hover {
      filter: brightness(1.1);
    }

    &:disabled {
      background: grey;
      border-color: grey;

      &:hover {
        filter: brightness(1.0);
      }
    }

    &:not(:disabled):not(.disabled):active {
      background: ${props => props.theme.secondary};
      border-color: ${props => props.theme.secondary};
      box-shadow: 0 0 0 0.2rem rgb(241 95 34 / 0.5);
    }

    &:focus {
      background: ${props => props.theme.secondary};
      filter: brightness(1.1);
      box-shadow: 0 0 0 0.2rem rgb(241 95 34 / 0.5);
    }

    &:focus, &:hover, &:active {
      color: white;
    }
  }

  .btn-secondary-outline {
    cursor: pointer;
    background: transparent;
    border-color: ${props => props.theme.secondary};
    color: ${props => props.theme.secondary};

    &:hover {
      background: ${props => props.theme.secondary};
      filter: brightness(1.1);
    }

    &:not(:disabled):not(.disabled):active {
      background: ${props => props.theme.secondary};
      border-color: ${props => props.theme.secondary};
      box-shadow: 0 0 0 0.2rem rgb(241 95 34 / 0.5);
    }

    &:focus {
      background: ${props => props.theme.secondary};
      filter: brightness(1.1);
      box-shadow: 0 0 0 0.2rem rgb(241 95 34 / 0.5);
    }

    &:focus, &:hover, &:active {
      color: white;
    }
  }

  &.btn-clear {
    background: transparent;
    color: white;
    border: 1px solid white;
    &:hover {
      background: white;
      color: ${props => props.theme.gray800};
    }
  }

  &.btn-primary-clear {
    background: transparent;
    color: ${props => props.theme.primary};
    border: 1px solid ${props => props.theme.primary};
    font-weight: 500;
    &:hover {
      background: ${props => props.theme.primary};
      color: white;
    }
  }

  &.btn-play {
    &::after {
      content: '';
      width: 18px;
      height: 18px;
      background: url('/static/images/icons/icon-play.svg') no-repeat 50%;
      background-size: 18px 18px;
      vertical-align: bottom;
      margin-left: 12px;
      display: inline-block;
    }
    &:hover {
      &::after {
        background-image: url('/static/images/icons/icon-play-dark.svg');
      }
    }
  }
  &.btn-secondary-orange {
    background-color: ${props => props.theme.secondaryOrange};
    color: white;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      color: white;
      opacity: 0.8;
    }

    &:disabled {
      cursor: default;
    }
  }
  &.btn-rounded {
    border-radius: 25px;
  }

  .btn-has-lock {
    &:before {
      content: '';
      display: inline-block;
      height: 0.75em;
      width: 0.75em;
      background: url('/icons/lock.svg') no-repeat center;
      background-size: 100%;
      margin-right: 0.3em;
    }
  }

  .input-lg {
    ${props => props.theme.media.md} {
      height: 78px;
      font-size: 1.25rem;
    }
  }

  .text-transform-none {
    text-transform: none;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .is-loading {
    position: relative;

    input {
      pointer-events: none;
      user-select: none;
    }

    &::before {
      position: absolute;
      content: '';
      top: -0;
      left: -0;
      background: rgba(255, 255, 255, 0.95);
      width: 100%;
      height: 100%;
      z-index: 100;
    }

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -1.5em 0em 0em -1.5em;
      width: 3em;
      height: 3em;
      animation: ${formSpin} 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 50%;
      border-color: ${props =>
    props.theme.primary} rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1)
        rgba(0, 0, 0, 0.1);
      border-style: solid;
      border-width: 0.2em;
      box-shadow: 0px 0px 0px 1px transparent;
      visibility: visible;
      z-index: 101;
    }
  }

  .form-control:disabled, .form-control[readonly] {
    color: ${props => props.theme.gray400};
    background: ${props => props.theme.gray100};
    user-select: none;
  }

  .form-control {
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 3rem;
    padding: 0.5rem;
  }

  label.float {
    position: relative;
    width: 100%;

    &.is-raised,
    &:focus-within {
    .label-text,
    .label-text.is-invalid {
      position: absolute;
      font-size: 0.8rem;
      top: 0.75rem;
      + .form-control {
        padding: 1.3rem 0.5rem 0.4rem;
      }
    }
  }

  .label-text {
    color: rgb(129, 138, 145);
    position: absolute;
    top: 1.5rem;
    left: 0.5rem;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    max-width: calc(100% - 1rem);
    overflow: hidden;
    white-space: nowrap;

      &.is-invalid {
        top: 0.8rem;
      }
    }

    select {
      appearance: none;
      background: url('/static/images/icons/dropdown-arrow.svg') no-repeat center right 10px;
      background-size: 10px 10px;
      &.form-control {
        background-color: #fff;
        line-height: 1.2;
        padding: 1.3rem 0.5rem 0.4rem;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  .no-overflow {
    overflow: hidden;
  }

  #info-bubble {
    &::after {
      bottom: -8px;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #fff;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      content:''; 
      display: inline-block;
      height: 0;
      right: -19px;
      top: 50%;
      transform: translate(0, -50%);
      width: 0;
      position: absolute;
    }
  }
`;

export default GlobalStyle;
