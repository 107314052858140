import Axios from 'axios';
import { SiteAPI } from '../config';

const config = Axios.get(SiteAPI.websiteConfig);

export const getProductsInCart = () => localStorage.getItem('cart') || '{}';

export const getACHAccountTypes = async () => {
  const { data } = await config;
  return data.sales.ach_account_types;
};
