/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { getProductsInCart } from '../../services/CartService';

const CartContext = React.createContext();

class CartProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsInCart: 0,
    };
  }

  componentDidMount() {
    this.updateNumOfProdsInCart();
  }

  addToCart = product => {
    const products = JSON.parse(getProductsInCart());
    const productId = product.id;
    products[productId] = product;
    localStorage.setItem("cart", JSON.stringify(products));
    this.updateNumOfProdsInCart();
  };

  isProductInCart = product => {
    const products = getProductsInCart();
    if (products[product.id]) {
      return true;
    }
    return false;
  };

  removeItem = id => {
    const products = JSON.parse(getProductsInCart());
    const keys = Object.keys(products);
    const filtered = {};
    keys.forEach(key => {
      if (key !== id) {
        filtered[key] = products[key];
      }
    });
    localStorage.setItem("cart", JSON.stringify(filtered));
    this.updateNumOfProdsInCart();
  };

  updateNumOfProdsInCart() {
    const products = JSON.parse(getProductsInCart());
    const itemsInCart = Object.keys(products).length;
    this.setState({ itemsInCart });
  }

  render() {
    return (
      <CartContext.Provider
        value={{
          state: this.state,
          addToCart: this.addToCart,
          removeItem: this.removeItem,
        }}
      >
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export const CartConsumer = CartContext.Consumer;
export default CartProvider;
