import styled, { keyframes } from 'styled-components';

export const formSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const FormStyles = styled.form`
  position: relative;
  padding: 30px 20px;
  border-radius: 4px;
  background: ${props => props.theme.gray100};
  border: 1px solid ${props => props.theme.gray200};
  justify-content: space-between;
  h2 {
    line-height: 1;
    margin-bottom: 1em;
    width: 100%;
  }
  h3 {
    color: ${props => props.theme.textColor};
    width: 100%;
    text-align: left;
  }
  .input-wrap {
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
    label {
      font-weight: 400;
      font-size: 0.9125rem;
      color: ${props => props.theme.gray700};
    }
  }
  .captcha-wrap {
    text-align: center;
    > div > div {
      text-align: center;
      > div {
        display: inline-block;
      }
    }
  }
  .btn-wrap {
    text-align: center;
    width: 100%;
  }
  .success {
    text-align: center;
    width: 100%;
    margin: 0 0 15px;
    padding: 15px;
    background: rgba(32, 198, 104, 0.4);
    color: #0f6333;
    font-size: 18px;
    border-radius: 4px;
    h5 {
      font-weight: 400;
      font-size: 1.5rem;
      margin: 0.5em 0;
    }
  }
  .invalid-captcha {
    color: #dc3545;
    font-size: 0.8125rem;
    iframe {
      border: 1px solid #dc3545;
    }
  }
  &.transparent {
    background: transparent;
    h3 {
      color: white;
    }
    .input-wrap {
      label {
        color: white;
      }
    }
  }
`;
