export const CBAPI = process.env.NEXT_PUBLIC_CBAPI;

export const WORDPRESS = process.env.NEXT_PUBLIC_WORDPRESS;
// export const PROXIED_WP = process.env.NEXT_PUBLIC_DOMAIN+"/content";
export const PROXIED_WP = process.env.NEXT_PUBLIC_WORDPRESS;

export const SiteAPIURL = `${CBAPI}/cbapi/app.php/`;

export const SF_API = {
  TOKEN: '5cH3du13RApP01nTm3nT34',
  key: 'zHtj1b14s4n@t23Bu11f@5t',
};

export const WordPressAPI = {
  url: `${WORDPRESS}/wp-json/`,
  pages: `${WORDPRESS}/wp-json/wp/v2/pages`,
  posts: `${WORDPRESS}/wp-json/wp/v2/posts`,
  options: `${WORDPRESS}/wp-json/acf/v3/options/options`,
  categories: `${WORDPRESS}/wp-json/wp/v2/categories`,
  media: `${WORDPRESS}/wp-content/uploads`,
  proxiedPages: `${PROXIED_WP}/wp-json/wp/v2/pages`,
};

export const SiteAPI = {
  // will remove websiteCongig after copying option values to website
  websiteConfig: `${SiteAPIURL}website/config`,
  checkUser: `${SiteAPIURL}website/api/check_user`,
  currentUser: `${SiteAPIURL}website/api/user/current`,
  careers: `${SiteAPIURL}website/job`,
  register: `${SiteAPIURL}website/register`,
  getStarted: `${SiteAPIURL}website/get_started`,
  registerNpv: `${SiteAPIURL}website/register_npv`,
  contactMessage: `${SiteAPIURL}website/contact_message`,
  registerLLC: `${SiteAPIURL}website/register_llc?leadSource=1-800Accountant&leadType=LLC`,
  portal: `${SiteAPIURL}website/api/user/current/portal`,
  callMeNow: `${SiteAPIURL}website/call_me_now`,
  businessInfo: `${SiteAPIURL}website/user`,
  products: {
    bookkeeping: `${SiteAPIURL}website/api/product/bookkeeping`,
    taxPrep: `${SiteAPIURL}website/api/product/tax-preparation`,
    payroll: `${SiteAPIURL}website/api/product/payroll`,
    auditDefense: `${SiteAPIURL}website/api/product/audit-defense`,
    taxSmart: `${SiteAPIURL}website/api/product/tax-smart`,
    taxExtension: `${SiteAPIURL}website/api/product/extensions`,
    smallBusinessTaxes: `${SiteAPIURL}website/api/product/small-business-taxes`,
  },
  sales: {
    order: `${SiteAPIURL}website/api/order`,
    paymentMethods: `${SiteAPIURL}website/api/payment_method`,
  },
  credentials: {
    website: {
      client_id:
        'website.web_5v7h3t1qwett4j6enzj0o81mv22jmr6whw2o07znwzuwtdae9x',
      client_secret: '3m639bgwndhjh32bk7vxgid7qyfert920ox75glkh0vh01b4ef',
    },
    portal: {
      client_id:
        'clientportal.web_3artrer81easscooooc0ww40ksk4wg008ws088g0k4go8sw0o8',
      client_secret: '53vvquws3ecc8ww08wo4og4o00kkg8ks0k0gkwg0o448sosg80',
    },
  },
  appointments: {
    availableTimeSlots: `${SiteAPIURL}website/appointment/available_slots`,
    setAppointment: `${SiteAPIURL}website/appointment`,
    getAppointment: `${SiteAPIURL}website/appointment`,
  },
  partner: {
    register: `${SiteAPIURL}website/register_partner`,
  },
  zipCodeAPI: {
    apiKey: 'ExnMtalMURlmwRLsUuXefNZJ9Qw739HGRYRauz3x3n1CTralpxhyAjBbuSqvbg5x',
    applicationKey:
      'fxyGk9llUonaSEULLrHgFksKA9aueAk1xJcqavP5yO5TKjia74m64gcE2yXITxCx',
    clientKey:
      'js-h4NL4JjpIFVnMYyOUjPRLFxVEOgsrYdDsP4ShHSY4ThdadOys8LfVOu20QHfMcsZ',
  },
  termsOfService: `${SiteAPIURL}website/terms_of_service`,
  privacyPolicy: `${SiteAPIURL}website/privacy_policy`,
};
