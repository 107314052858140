import React, { Component } from 'react';
import Router from 'next/router';
import Cookies from 'js-cookie';
import axios from 'axios';
import { SiteAPI, SiteAPIURL } from '../../config';
import UserService from '../../services/UserService';

export const UserContext = React.createContext();

class UserProvider extends Component {
  constructor() {
    super();
    this.UserService = new UserService();
    this.state = {
      user: false,
    };
  }

  componentDidMount = async () => {
    if (!this.state.user) {
      const user = await this.UserService.getCurrentUser();
      this.setState({ user });
    }
  };

  signOut = () => {
    this.setState({ user: false });
    localStorage.removeItem('token');
    Cookies.remove('userId');
    Cookies.remove('partner_user_ID');

    const currentLocation = window.location.href;

    Router.push(
      `${SiteAPIURL}logout?client_id=${SiteAPI.credentials.portal.client_id
      }&client_secret=${SiteAPI.credentials.portal.client_secret
      }&response_type=token&redirect_uri=${encodeURIComponent(
        currentLocation
      )}&immediate_redirect=true`
    );
  };

  registerUser = async (userData, redirect = false) => {
    const queryData = JSON.parse(localStorage.getItem('query')) || {};

    try {
      const obj = {
        address: userData.address,
        annualRevenue: userData.annualRevenue,
        businessType: userData.businessType,
        businessName: userData.businessName,
        campaign: userData.campaign,
        channel: userData.channel,
        city: userData.city,
        device: queryData.device,
        doingForServiceToday: userData.doingForServiceToday,
        email: encodeURI(userData.email),
        EmailCampaignID: userData.EmailCampaignID,
        expected1stYearRevenue: userData.expected1stYearRevenue,
        firstName: userData.first_name,
        formState: userData.formState,
        GCLID: queryData.gclid,
        areasNeedingHelp: userData.areasNeedingHelp,
        industry: userData.industry,
        lastName: userData.last_name,
        leadSource: userData.leadSource,
        leadType: userData.leadType,
        channel: userData.channel,
        campaign: userData.campaign,
        location: queryData.location,
        monthlyRevenue: userData.monthlyRevenue,
        MSCLKID: queryData.msclkid,
        ownedBusinessBefore: userData.ownedBusinessBefore,
        phone: userData.phone ? userData.phone.replace(/\D/g, '') : '',
        referringAgentsName: userData.referring_agents_name,
        salesRepId: userData.sales_rep_id,
        salesRepName: userData.sales_rep_name,
        salesRepSTW: userData.sales_rep_stw,
        state: userData.state,
        UTMCampaign: queryData.utm_campaign,
        UTMContent: queryData.utm_content,
        UTMMedium: queryData.utm_medium,
        UTMSource: queryData.utm_source,
        UTMTerm: queryData.utm_term,
        zip: userData.zip,
      };

      const { data } = await axios.post('/api/backend/nwapi/contacts', obj);
      if (redirect) {
        Router.push(redirect);
      }
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          state: this.state,
          registerUser: this.registerUser,
          signOut: this.signOut,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;
export default UserProvider;
